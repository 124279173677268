import * as React from 'react'
import * as css from './TeamWithDescriptions.module.css'
import Employee from 'components/employee/Employee'
import SanityBlock from 'components/common/SanityBlock'

const Team = ({ title, team, employees }) => {
  return (
    <section className="container padding">
      {title && (
        <header className={css.header}>
          <h2 className="uppercase">{title}</h2>
        </header>
      )}

      <ul>
        {(team?.employees || employees)?.map((employee, key) => (
          <li id={employee.seo?.slug.current} className={css.employee}>
            <Employee {...employee} key={key} />
            <SanityBlock body={employee._rawDescription} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Team
