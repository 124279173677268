import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { graphql, useStaticQuery } from 'gatsby'
import Hero from 'components/pg-our-team/Hero'
import TeamWithDescriptions from 'components/common/TeamWithDescriptions'

const OurTeamPg = () => {
  const {
    sanityOurTeamPg: pg,
    allSanityEmployeeList,
  } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <Hero {...pg.hero} />

      {allSanityEmployeeList.nodes.map((team, key) => (
        <TeamWithDescriptions {...team} key={key} />
      ))}
    </DefaultLayout>
  )
}

export default OurTeamPg

const query = graphql`query OurTeamPg {
  sanityOurTeamPg {
    hero { ...hero }
    seo {
      title
      description
    }
  }
  allSanityEmployeeList(sort: {fields: order}) {
    nodes {
      ...employees
    }
  }
}`
