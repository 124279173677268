import * as React from 'react'
import SanityBlock from 'components/common/SanityBlock'

const Hero = ({ title, _rawDescription }) => (
  <section className="container padding richtext">
    <h1 className="h1">{title}</h1>
    <SanityBlock body={_rawDescription} />
  </section>
)

export default Hero
